import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  ProjectPage,
  Paragraph,
  Heading,
  Subheading,
} from "../components/layouts/ProjectPage";
import Separator from "../components/common/Separator";
import {
  Content,
  Goal,
  GoalsList,
  Purpose,
  PurposeParagraph,
  Stack,
  StackExplainer,
  StackRow,
  TechItem,
  TechList,
} from "../components/monty/styles";
import projects from "../data/projects";

function Monty(): JSX.Element {
  const logos = useStaticQuery(graphql`
    query {
      learnbrite: file(relativePath: { eq: "logos/learnbrite.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }

      gaze: file(relativePath: { eq: "logos/gaze.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
    }
  `);

  const { name, notice, sting, purpose, additional, stack, stackExplanation } =
    projects[2];

  const seo = {
    title: `${name}`,
    description: `This project is all about self-knowledge and giving estimates that are a little less BS than usual!`,
  };

  const title = {
    main: name,
    sub: sting,
  };

  const otherProjects = [
    {
      title: "Learnbrite & Mootup",
      url: "/learnbrite",
      thumbnail: logos.learnbrite.childImageSharp.gatsbyImageData,
    },
    {
      title: "Gaze of the World",
      url: "/gaze",
      thumbnail: logos.gaze.childImageSharp.gatsbyImageData,
    },
  ];

  return (
    <ProjectPage
      seo={seo}
      title={title}
      otherProjects={otherProjects}
      notice={notice}
    >
      <Content>
        <Purpose>
          <Heading>Purpose</Heading>
          {purpose &&
            purpose.map((paragraph) => (
              <PurposeParagraph key={paragraph.key}>
                <Paragraph dangerouslySetInnerHTML={paragraph} />
              </PurposeParagraph>
            ))}
        </Purpose>
        <Separator />
        <GoalsList>
          <Heading>What are my goals?</Heading>
          {additional.map((learning) => (
            <Goal key={learning.key}>
              <Subheading>{learning.title}</Subheading>
              <Paragraph dangerouslySetInnerHTML={learning} />
            </Goal>
          ))}
        </GoalsList>
        <Separator isRotated />
        <Stack>
          <Heading>The stack</Heading>
          <StackRow>
            <TechList>
              <div>
                {stack &&
                  stack.map((tech: string, index: number) => (
                    <TechItem key={tech} order={index}>
                      {tech}
                    </TechItem>
                  ))}
              </div>
            </TechList>
            <StackExplainer>
              <Paragraph>{stackExplanation}</Paragraph>
            </StackExplainer>
          </StackRow>
        </Stack>
      </Content>
    </ProjectPage>
  );
}

export default Monty;
